import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mb-8 text-gray-800">
    <div className="lg:flex -mx-3">
      <div className="flex-1 px-3 pb-5">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-1">SoftOps is a team of creative thinkers and adept coders. We place primary value on solid engineering and innovative problem solving.</p>
      </div>
      <div className="flex-1 px-3 pb-5">
        <h2 className="text-lg font-semibold">Contacts</h2>
        <ul className="mt-1 leading-loose">
          <li>
            <a href="mailto:softops.contact@gmail.com">softops.contact@gmail.com</a>
          </li>
          <li>
            <a href="tel:+37376739841">+373 767 39 8 41</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3 pb-5">
      <h2 className="text-lg font-semibold ">Social Links</h2>
      <ul className="mt-1 leading-loose">
        <li>
          <a href="https://t.me/softops">Telegramm</a>
        </li>
        <li>
          <a href="https://www.facebook.com/softops.pro">Facebook</a>
        </li>
      </ul>
    </div>
    </div>
  </footer>
);

export default Footer;
